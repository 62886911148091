<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    :formatted-columns="formattedColumns"
    :allow-duplicate="false"
    :custom-query="customQuery"
    single-edit
    authority="Users_User_Group"
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../userGroupStoreModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'user-groups',
        endpoint: 'user-groups',
        route: 'user-groups',
        title: {
          single: this.$t('User Group Add'),
          plural: this.$t('User Groups'),
        },
      }
    },
    customQuery() {
      return({category: "PRODUCT"})
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('name'), sortable: true },
        { key: 'subtitle', label: this.$t('subtitle'), sortable: true },
        { key: 'enabled', label: this.$t('enabled') },
        { key: 'subscription', label: this.$t('Subscription') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'enabled', type: 'boolean' },
        { key: 'subscription', type: 'boolean' },
      ]
    },

    formFields() {
      return [
        {
          id: 'name',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('description'),
          required: true,
          rules: 'min:4',
        },
        {
          id: 'disabled',
          type: 'checkbox',
          label: this.$t('Disabled'),
        },
      ]
    },
  },
}
</script>
